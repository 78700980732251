<template>
	<div>
		<enterprises-edit-cy v-if="enterpriseModel==0"></enterprises-edit-cy>
		<enterprises-edit-sc v-else-if="enterpriseModel==1"></enterprises-edit-sc>
		<enterprises-edit-xs v-else-if="enterpriseModel==2"></enterprises-edit-xs>
		<enterprises-edit-yd v-else-if="enterpriseModel>=9&&enterpriseModel<=19"></enterprises-edit-yd>
		<enterprises-edit-ts v-else-if="enterpriseModel==20"></enterprises-edit-ts>
	</div>
</template>

<style scoped>
</style>

<script>
	import EnterpriseEditJs from "@/js/EnterpriseEdit";
	import EnterprisesEditCy from "./EnterpriseEditCy.vue";
	import EnterprisesEditSc from "./EnterpriseEditSc.vue";
	import EnterprisesEditTs from "./EnterpriseEditTs.vue";
	import EnterprisesEditXs from "./EnterpriseEditXs.vue";
	import EnterprisesEditYd from "./EnterpriseEditYd.vue";

	export default {
		components: {
			EnterprisesEditCy,
			EnterprisesEditSc,
			EnterprisesEditTs,
			EnterprisesEditXs,
			EnterprisesEditYd,
		},
		...EnterpriseEditJs,
	};
</script>
