<template>
	<div class="enterpriseCy">
		<div class="top-title">
			<van-icon name="arrow-left" class="icon-fan" @click="fan()" />
			<span>特设信息上报</span>
			<van-icon name="add-o" class="icon-right" @click="addRestaurant()" />
		</div>
		<div class="alls">
			<van-row class="van-row">
				<van-col span="9" class="text-left">单位名称</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="enterpriseName" placeholder="请输入单位名称" />
				</van-col>
			</van-row>
			<van-row class="van-row">
				<van-col span="9" class="text-left">统一社会信用代码</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="enterpriseNumber" placeholder="请输入统一社会信用代码" />
				</van-col>
			</van-row>
			<van-row class="van-row">
				<van-col span="9" class="text-left">联系人</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="enterpriseContact" placeholder="请输入联系人" />
				</van-col>
			</van-row>
			<van-row class="van-row">
				<van-col span="9" class="text-left">联系电话</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="enterpriseContactTel" placeholder="请输入联系电话" />
				</van-col>
			</van-row>
			<van-row class="van-row">
				<van-col span="9" class="text-left">法定代表人</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="enterpriseHead" placeholder="请输入法定代表人" />
				</van-col>
			</van-row>
			<van-row class="van-row">
				<van-col span="9" class="text-left">重点监控单位</van-col>
				<van-col span="15" class="text-right" @click="selectEnterpriseIsMain()">{{enterpriseIsMainEn}}</van-col>
			</van-row>
			<van-row class="van-row">
				<van-col span="9" class="text-left">最低检查频次</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="enterpriseMinCheck" type="number" placeholder="请输入最低检查频次" />
				</van-col>
			</van-row>
			<van-row class="van-row">
				<van-col span="9" class="text-left">单位属性</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="enterpriseAttribute" placeholder="请输入单位属性" />
				</van-col>
			</van-row>
			<!-- <van-row class="van-row">
				<van-col span="9" class="text-left">档案号</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="enterpriseArchives" placeholder="请输入档案号" />
				</van-col>
			</van-row> -->
			<van-row class="van-row">
				<van-col span="9" class="text-left">所属辖区</van-col>
				<van-col span="15" class="text-right" @click="selectEnterpriseGame()">{{enterpriseGameEn}}</van-col>
			</van-row>
			<van-row class="van-row">
				<van-col span="9" class="text-left">监管人员</van-col>
				<van-col span="15" class="text-right text-hide" @click="selectEnterpriseUser()">{{enterpriseUserEn}}</van-col>
			</van-row>
			<van-row class="van-row">
				<van-col span="9" class="text-left">风险等级</van-col>
				<van-col span="15" class="text-right" @click="selectEnterpriseLevel()">{{enterpriseLevelEn}}</van-col>
			</van-row>
			<van-row class="van-row">
				<van-col span="9" class="text-left">单位地址</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="enterpriseAddress" readonly placeholder="单位地址（选择经纬度自动生成）" />
				</van-col>
			</van-row>
			<van-row class="van-row">
				<van-col span="9" class="text-left">经纬度</van-col>
				<van-col span="15" class="text-right" @click="selectEnterpriseAddress">
					<van-field v-model="enterpriseAddressText" readonly />
				</van-col>
			</van-row>
			<van-row class="van-row-img" style="padding-bottom: 1.466667rem">
				<van-col span="9" class="text-left">备注</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="enterpriseNote" rows="4" autosize type="textarea" style="height:3.066667rem;margin-top: .266667rem" placeholder="请输入备注" show-word-limit />
				</van-col>
			</van-row>

			<div class="submit-type">
				<van-button block type="info" color="#1989FA" @click="addE()" class="submit-button">确定</van-button>
			</div>
		</div>

		<van-popup v-model:show="selectPickerShow" round position="bottom" :style="{ width: '100%' }">
			<van-picker title="主体状态" show-toolbar :columns="enterpriseTypeList" @confirm="selectPickerConfirm" @cancel="selectPickerCancel" />
		</van-popup>

		<van-popup v-model:show="enterpriseGameShow" round position="bottom" :style="{ width: '100%' }">
			<van-picker title="所属辖区" show-toolbar :default-index="defaultIndex" :columns="enterpriseGameList" @confirm="enterpriseGameConfirm" @cancel="enterpriseGameCancel" />
		</van-popup>

		<van-popup v-model:show="enterpriseUserShow" position="bottom" :style="{ width: '100%' }">
			<div class="van-picker__toolbar">
				<button type="button" class="van-picker__cancel" @click="enterpriseUserCancel">取消</button>
				<button type="button" class="van-picker__confirm" @click="enterpriseUserConfirm">确认</button>
			</div>
			<div class="checkbox-con" style="max-height:7.04rem;overflow-y:auto">
				<van-checkbox-group v-model="enterpriseUserResult" @change="changeGroup">
					<van-cell-group>
						<van-cell class="van-cell-class" v-for="(item, index) in enterpriseUserList" clickable :key="index" :title="item.userNikeName" @click="toggle(item)">
							<template #right-icon>
								<van-checkbox :name="item.userId" ref="checkboxes" @click.stop />
							</template>
						</van-cell>
					</van-cell-group>
				</van-checkbox-group>
			</div>
		</van-popup>

		<van-popup v-model:show="enterpriseLevelShow" round position="bottom" :style="{ width: '100%' }">
			<van-picker title="监管等级" show-toolbar :default-index="defaultIndex" :columns="enterpriseLevelList" @confirm="enterpriseLevelConfirm" @cancel="enterpriseLevelCancel" />
		</van-popup>

		<van-popup v-model:show="enterpriseAddressShow" round position="bottom" :style="{ width: '100%' }">
			<div class="van-picker__toolbar">
				<button type="button" class="van-picker__cancel" @click="enterpriseAddressCancel">取消</button>
				<button type="button" class="van-picker__confirm" @click="enterpriseAddressConfirm">确认</button>
			</div>
			<!-- <van-field v-model="enterpriseAddressTemp" readonly style="width: 90%;margin-left: 5%;" /> -->

			<van-popover v-model:show="showAddList" trigger="manul" :actions="addressArr" placement="bottom">
				<van-grid column-num="1" direction="horizontal">
					<van-grid-item v-for="i in addressArr" :key="i.id" :text="i.address" @click="selectAddItem(i)"></van-grid-item>
				</van-grid>

				<template #reference>
					<van-field v-model="enterpriseAddressTemp">
						<template #button>
							<van-icon name="search" @click.stop="queryAddressByKeyWord" style="display:flex; font-size: 0.5rem" />
						</template>
					</van-field>
				</template>
			</van-popover>

			<div id="container"></div>
		</van-popup>

		<van-popup v-model:show="enterpriseIsMainShow" round position="bottom" :style="{ width: '100%' }">
			<van-picker title="是否为重点监控单位" show-toolbar :default-index="defaultIndex" :columns="enterpriseIsMainList" @confirm="enterpriseIsMainConfirm" @cancel="enterpriseIsMainCancel" />
		</van-popup>
	</div>
</template>
<style scoped>
	.top-title {
		width: 100%;
		height: 1.173333rem;
		line-height: 1.173333rem;
		background-color: #025193;
		color: white;
		position: fixed;
		z-index: 999;
		text-align: center;
		font-size: 0.49rem;
		letter-spacing: 0.026667rem;
	}

	.icon-fan {
		position: absolute;
		top: 0.28rem;
		left: 0.133333rem;
		font-size: 0.746667rem;
	}

	.icon-right {
		position: absolute;
		top: 0.28rem;
		right: 0.4rem;
		font-size: 0.746667rem;
	}

	.alls {
		padding-top: 1.173333rem;
		width: 96%;
		margin-left: 2%;
	}

	.text-left {
		font-size: 0.373333rem;
		padding: 0px 0.133333rem;
	}

	.text-right {
		font-size: 0.373333rem;
		padding: 0px 0.133333rem;
	}

	.text-right1 {
		font-size: 0.373333rem;
		padding: 0px;
		text-align: center;
	}

	.van-row {
		height: 1.066667rem;
		line-height: 1.066667rem;
		border-bottom: 1px #eeeeee solid;
	}

	.van-row-img {
		height: 3.466667rem;
		line-height: 3.466667rem;
		border-bottom: 1px #eeeeee solid;
	}

	.van-row-img-3 {
		min-height: 3.466667rem;
		height: auto;
		line-height: 3.466667rem;
		border-bottom: 1px #eeeeee solid;
	}

	.van-cell {
		padding: 0.15rem 0.133333rem;
	}

	.van-uploader {
		margin-top: 0.266667rem;
	}

	.van-cell-class {
		padding: 0.15rem 0.533333rem;
	}

	.submit-type {
		width: 100%;
		height: 1.45rem;
		padding-top: 0.15rem;
		background-color: white;
		position: fixed;
		bottom: 0px;
		left: 0rem;
	}

	.submit-button {
		width: 90%;
		margin-left: 5%;
		height: 1.2rem;
		border-radius: 0.16rem;
	}

	.van-icon {
		font-size: 0.5rem;
	}

	#container {
		width: 98%;
		margin-left: 1%;
		height: 10.666667rem;
		border-radius: 8px;
		margin-top: 0.4rem;
		margin-bottom: 0.4rem;
	}

	
</style>
<script>
	import EnterpriseEditTsJs from "@/js/edit/EnterpriseEditTs";
	export default {
		...EnterpriseEditTsJs,
	};
</script>
