import { getCurrentInstance, toRefs, reactive, inject, ref } from "vue";
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
	setup(props, context) {

		//开始创建组件之前
		const { proxy } = getCurrentInstance();
		
		const router = useRouter();

		const store = useStore();

		const dataE = inject('dataAll');

		const dataAll = reactive({
			test: ref(null),
			showDate: ref(false),
			showDateEnd: ref(false),
			selectPickerShow: ref(false),
			enterpriseGameList: ref(null),
			enterpriseGameEn: ref("请选择所属辖区"),
			enterpriseGameShow: ref(false),
			enterpriseUserEn: ref("请选择监管人员"),
			enterpriseUserShow: ref(false),
			enterpriseUserList: ref([]),
			enterpriseUserResult: ref([]),
			enterpriseUserResultName: ref([]),
			enterpriseLevelEn: ref("请选择监管等级"),
			enterpriseLevelList: ref(null),
			enterpriseLevelShow: ref(false),
			enterpriseStateEn: ref("请选择企业类型"),
			enterpriseStateList: ref(null),
			enterpriseStateShow: ref(false),
			enterpriseAddressShow: ref(false),
			enterpriseIsMainShow: ref(false),
			enterpriseIsMainEn: ref("请选择是否为重点监控"),
			enterpriseIsMainList: ref([]),

			//地图
			keyWords: ref(null),
			coordinate: ref(null),
			enterpriseAddressTemp: ref(null),
			enterpriseLongitudeTemp: ref(null),
			enterpriseLatitudeTemp: ref(null),
			enterpriseAddressText: ref("请选择经纬度"),

			relationId: ref(null),
			enterpriseId: ref(null),
			enterpriseModel: ref("20"),
			enterpriseQrNumber: proxy.$router.currentRoute.value.params.id,
			enterpriseName: ref(null),
			enterpriseType: ref(0),
			enterpriseNumber: ref(null),
			enterpriseContact: ref(null),
			enterpriseContactTel: ref(null),
			enterpriseHead: ref(null),
			enterpriseNote: ref(null),
			enterpriseGame: ref(null),
			enterpriseUser: ref(null),
			enterpriseLevel: ref(null),
			enterpriseState: proxy.$router.currentRoute.value.params.id.indexOf("G") == -1 ? "0" : "1",
			enterpriseAddress: ref(null),
			enterpriseLongitude: ref(null),
			enterpriseLatitude: ref(null),
			enterpriseMinCheck: ref(null),
			enterpriseIsMain: ref(null),
			enterpriseAttribute: ref(null),
			// enterpriseArchives: ref(null),
			defaultIndex: ref(null),

			showAddList: ref(false),
			addressArr: ref([])
		});

		if (dataE.enterpriseData != null) {
			Object.assign(dataAll, dataE.enterpriseData);

			if (dataAll.enterpriseLevel) {
				dataAll.enterpriseLevelEn = dataAll.enterpriseLevel;
			}

			dataAll.enterpriseAddressTemp = dataAll.enterpriseAddress;
			dataAll.enterpriseLongitudeTemp = dataAll.enterpriseLongitude;
			dataAll.enterpriseLatitudeTemp = dataAll.enterpriseLatitude;
			dataAll.enterpriseAddressText = dataAll.enterpriseLatitude + "," + dataAll.enterpriseLongitude;

			if (dataAll.userTsMapList != null && dataAll.userTsMapList[0] != undefined) {
				let nameArr = dataAll.userTsMapList.map(u=>{ return u.userNikeName})
				dataAll.enterpriseUserEn = nameArr? (nameArr.length>1? nameArr.join('、') : nameArr[0]) : '请选择监管人员'
				dataAll.enterpriseUserResult = dataAll.userTsMapList.map(u=>{return u.userId});
				dataAll.enterpriseUserResultName = nameArr
			}
		}

		const fan = () => {
			router.push({
				path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
			});
		}

		const addRestaurant = () => {
			store.commit('enterprise/setEditData', dataAll)
			router.push({ path: '/enterpriseAdd/1' });
		}

		const selectEnterpriseGame = () => {
			proxy.$http({
				url: proxy.$http.adornUrl(`/sys/type/getGame`),
				method: 'post',
				data: null,
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then(({
				data
			}) => {
				if (data && data.code === 0) {
					const array = [];
					for (var i = 0; i < data.type.length; i++) {
						array.push({
							text: data.type[i].gameName,
							id: data.type[i].gameId
						});
					}
					dataAll.enterpriseGameList = array;
					dataAll.defaultIndex = dataAll.enterpriseGameList.findIndex((ace) => ace.id == dataAll
						.enterpriseGame);
					dataAll.enterpriseGameShow = true;
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		const enterpriseGameCancel = () => {
			dataAll.enterpriseGameShow = false;
		}
		const enterpriseGameConfirm = (value) => {
			if (dataAll.enterpriseGame != value.id) {
				dataAll.enterpriseUser = null;
				dataAll.enterpriseUserResultName = [];
				dataAll.enterpriseUserResult = [];
				dataAll.enterpriseUserEn = "请选择监管人员";
			}
			dataAll.enterpriseGameEn = value.text;
			dataAll.enterpriseGame = value.id;
			dataAll.enterpriseGameShow = false;
		}

		const selectEnterpriseUser = () => {
			if (!dataAll.enterpriseGame) {
				proxy.$toast.fail("请先选择所属辖区");
				return;
			}
			proxy.$http({
				url: proxy.$http.adornUrl(`/sys/user/listAll`),
				method: 'post',
				data: {
					'userGame': dataAll.enterpriseGame
				},
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then(({
				data
			}) => {
				if (data && data.code === 0) {
					dataAll.enterpriseUserList = data.list;
					dataAll.enterpriseUserShow = true;
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		const enterpriseUserCancel = () => {
			dataAll.enterpriseUserShow = false;
			dataAll.enterpriseUserResult = dataAll.userTsMapList.map(u=>{return u.userId});
		}
		const enterpriseUserConfirm = (value) => {
			dataAll.enterpriseUserResultName = []
			dataAll.enterpriseUserList.forEach(item => {
				dataAll.enterpriseUserResult.forEach(_item => {
					if (item.userId == _item) {
						dataAll.enterpriseUserResultName.push(item.userName);
					}
				})
			})
			dataAll.enterpriseUserEn = dataAll.enterpriseUserResultName? 
			(dataAll.enterpriseUserResultName.length>1? dataAll.enterpriseUserResultName.join('、') : dataAll.enterpriseUserResultName[0])
			:'请选择监管人员';
			dataAll.enterpriseUserShow = false;
		}
		
		const toggle = (item) => {
			const index = dataAll.enterpriseUserResult.indexOf(item.userId);
			if (index == -1) {
				dataAll.enterpriseUserResult.push(item.userId);
			} else {
				dataAll.enterpriseUserResult.splice(index, 1);
			}
		}

		const changeGroup = (val) => {
			dataAll.enterpriseUserResult = val;
		}

		const selectEnterpriseLevel = () => {
			dataAll.enterpriseLevelList = [
				{ text: "A" }, 
				{ text: "B" }, 
				{ text: "C" }, 
				{ text: "D" }
			];
			dataAll.defaultIndex = dataAll.enterpriseLevelList.findIndex((ace) => ace.text == dataAll.enterpriseLevel);
			dataAll.enterpriseLevelShow = true;
		}
		const enterpriseLevelCancel = () => {
			dataAll.enterpriseLevelShow = false;
		}
		const enterpriseLevelConfirm = (value) => {
			dataAll.enterpriseLevelEn = value.text;
			dataAll.enterpriseLevel = value.text;
			dataAll.enterpriseLevelShow = false;
		}

		const addE = () => {
			// let json1 = JSON.stringify(dataAll.enterpriseUserResult).trim();
			const json = {
				relationId: dataAll.relationId,
				enterpriseId: dataAll.enterpriseId,
				enterpriseModel: dataAll.enterpriseModel,
				enterpriseName: dataAll.enterpriseName,
				enterpriseType: dataAll.enterpriseType,
				enterpriseNumber: dataAll.enterpriseNumber,
				enterpriseContact: dataAll.enterpriseContact,
				enterpriseContactTel: dataAll.enterpriseContactTel,
				enterpriseHead: dataAll.enterpriseHead,
				enterpriseNote: dataAll.enterpriseNote,
				enterpriseGame: dataAll.enterpriseGame,
				enterpriseUser: dataAll.enterpriseUserResult.join(','),
				enterpriseLevel: dataAll.enterpriseLevel,
				enterpriseState: dataAll.enterpriseState,
				enterpriseQrNumber: dataAll.enterpriseQrNumber,
				enterpriseAddress: dataAll.enterpriseAddress,
				enterpriseLongitude: dataAll.enterpriseLongitude,
				enterpriseLatitude: dataAll.enterpriseLatitude,
				enterpriseMinCheck: dataAll.enterpriseMinCheck,
				enterpriseAttribute: dataAll.enterpriseAttribute,
				// enterpriseArchives: dataAll.enterpriseArchives,
				enterpriseIsMain: dataAll.enterpriseIsMain
			}

			proxy.$http({
				url: proxy.$http.adornUrl(`/app/qr/addUpdateEnterprise`),
				method: 'post',
				data: json,
				headers: {
					token: sessionStorage.getItem("userToken")
				},
			}).then(({
				data
			}) => {
				if (data && data.code === 0) {
					router.push({
						path: '/enterpriseJump/' + dataAll.enterpriseQrNumber
					});
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		let geolocation = new qq.maps.Geolocation("6R5BZ-HBYWQ-XW25T-GFO7X-UMMLS-FEFXU", "qr");
		let options = { timeout: 9000 };
		let map = {}
		let marker = {}
		let mapOptions = {
			zoom: 15, //设置地图缩放级别
			// center: myLatlng, //设置中心点样式
			mapTypeId: qq.maps.MapTypeId.ROADMAP //设置地图样式详情参见MapType
		}

		let showErr = () => {}

		let showPosition = (position) => {
			if (!dataAll.enterpriseLatitude && !dataAll.enterpriseLongitude) {
				dataAll.enterpriseAddressTemp = position.nation + position.city + position.addr;
				dataAll.enterpriseLongitudeTemp = position.lng;
				dataAll.enterpriseLatitudeTemp = position.lat;
			}

			//步骤：定义map变量 调用 qq.maps.Map() 构造函数   获取地图显示容器
			//设置地图中心点
			var myLatlng = new qq.maps.LatLng(dataAll.enterpriseLatitudeTemp, dataAll.enterpriseLongitudeTemp);
			mapOptions.center = myLatlng

			//获取dom元素添加地图信息
			map = new qq.maps.Map(document.getElementById("container"), mapOptions);

			//创建一个Marker
			marker = new qq.maps.Marker({
				//设置Marker的位置坐标
				position: myLatlng,
				//设置显示Marker的地图
				map: map
			});

			//给地图添加点击事件
			//并获取鼠标点击的经纬度
			qq.maps.event.addListener(map, 'click', function(event) {
				console.log(event);
				//创建一个Marker
				marker.setMap(null);
				marker = new qq.maps.Marker({
					//设置Marker的位置坐标
					position: new qq.maps.LatLng(event.latLng.getLat(), event.latLng.getLng()),
					//设置显示Marker的地图
					map: map
				});
				dataAll.enterpriseLongitudeTemp = event.latLng.getLng();
				dataAll.enterpriseLatitudeTemp = event.latLng.getLat();
				proxy.$http({
					url: proxy.$http.adornUrl(`/app/qr/getAddress`),
					method: 'post',
					data: {
						enterpriseLongitude: event.latLng.getLng(),
						enterpriseLatitude: event.latLng.getLat()
					},
					headers: {
						token: sessionStorage.getItem("userToken")
					},
				}).then(({
					data
				}) => {
					if (data.code === 401) {
						sessionStorage.setItem("userToken", "");
						router.push({
							path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
						});
					}
					dataAll.enterpriseAddressTemp = data.data.result.address + data.data.result.formatted_addresses.recommend;
				})
			});
		}
		const selectEnterpriseAddress = () => {
			dataAll.enterpriseAddressShow = true;
			window.setTimeout(function() {
				geolocation.getLocation(showPosition, showErr, options);
			}, 380);
		}
		const enterpriseAddressCancel = () => {
			dataAll.enterpriseAddressShow = false;
			dataAll.enterpriseAddressTemp = dataAll.enterpriseAddress;
		}
		const enterpriseAddressConfirm = () => {
			dataAll.enterpriseAddress = dataAll.enterpriseAddressTemp;
			dataAll.enterpriseLongitude = dataAll.enterpriseLongitudeTemp;
			dataAll.enterpriseLatitude = dataAll.enterpriseLatitudeTemp;
			dataAll.enterpriseAddressText = dataAll.enterpriseLatitudeTemp + "," + dataAll.enterpriseLongitudeTemp;
			dataAll.enterpriseAddressShow = false;
		}

		const queryAddressByKeyWord = () => {
			let keyword = dataAll.enterpriseAddressTemp
			proxy.$http.request({
				url: proxy.$http.adornUrl(`/app/qr/getDegreeList?address=${keyword}`),
			    method: 'get',
			    headers:{
			      	token:sessionStorage.getItem("userToken")
			    }
			}).then(({data}) => {
				if(data&&data.code==0) {
					let addArr = data.data.map(a=>{ return { id: a.id, address: a.address, location: a.location }})
					dataAll.addressArr = addArr
					dataAll.showAddList = true
				}else if(data.code===401){
					sessionStorage.setItem("userToken","");
			        router.push({path:'/enterpriseJump/'+proxy.$router.currentRoute.value.params.id});
				}else{
					proxy.$toast.fail(data.msg);
				}
			})
		}

		const selectAddItem = (item) => {
			dataAll.showAddList = false
			if(item.address) {
				dataAll.enterpriseLatitudeTemp = item.location.lat
				dataAll.enterpriseLongitudeTemp = item.location.lng
				dataAll.enterpriseAddressTemp = item.address
				let centerPoint = new qq.maps.LatLng(dataAll.enterpriseLatitudeTemp, dataAll.enterpriseLongitudeTemp);
				if(map) {
					map.setCenter(centerPoint)
					marker.setPosition(centerPoint)
				} 
			}
		}

		const selectEnterpriseIsMain = () => {
			dataAll.enterpriseIsMainList = [
				{ text: "是", id: 1 }, 
				{ text: "否", id: 0 }
			];
			dataAll.defaultIndex = dataAll.enterpriseIsMainList.findIndex((ace) => ace.id == dataAll.enterpriseIsMain);
			dataAll.enterpriseIsMainShow = true;
		}
		const enterpriseIsMainCancel = () => {
			dataAll.enterpriseIsMainShow = false;
		}
		const enterpriseIsMainConfirm = (value) => {
			dataAll.enterpriseIsMainEn = value.text;
			dataAll.enterpriseIsMain = value.id;
			dataAll.enterpriseIsMainShow = false;
		}
		
		return {
			...toRefs(dataAll),
			fan,
			selectEnterpriseGame,
			enterpriseGameCancel,
			enterpriseGameConfirm,
			selectEnterpriseUser,
			enterpriseUserCancel,
			enterpriseUserConfirm,
			toggle,
			changeGroup,
			selectEnterpriseLevel,
			enterpriseLevelCancel,
			enterpriseLevelConfirm,
			addE,
			addRestaurant,
			selectEnterpriseAddress,
			enterpriseAddressCancel,
			enterpriseAddressConfirm,
			selectEnterpriseIsMain,
			enterpriseIsMainCancel,
			enterpriseIsMainConfirm,
			queryAddressByKeyWord,
			selectAddItem
		}
	}
}
