import { getCurrentInstance, watch, toRefs, inject, reactive, ref } from "vue";
import { useRouter } from 'vue-router'
import { useStore} from 'vuex'
import { dateToStrCopy } from '@/utils/date'
import { baseUrlImg } from '@/utils/common'
import Compressor from "compressorjs"

export default {
	setup(props, context) {

		document.title = "食品销售信息上报";
		//开始创建组件之前
		const { proxy } = getCurrentInstance();
		
		const router = useRouter();

		const store = useStore();

		const dataE = inject('dataAll')

		const dataAll = reactive({
			test: ref(null),
			dateTitle: "请选择时间",
			minDate: new Date(2010, 0, 1),
			maxDate: new Date(2110, 11, 31),
			currentDate: new Date(),
			dateTitleEn: "请选择时间",
			minDateEn: new Date(2010, 0, 1),
			maxDateEn: new Date(2110, 11, 31),
			currentDateEn: new Date(),
			showDate: ref(false),
			showDateEnd: ref(false),
			selectPickerShow: ref(false),
			enterpriseTypeList: ref(null),
			enterpriseTypeEn: ref("请选择主体状态"),
			xkImgList: ref([]),
			yyImgList: ref([]),
			
			enterpriseGameList: ref(null),
			enterpriseGameEn: ref("请选择所属辖区"),
			enterpriseGameShow: ref(false),

			enterpriseGridList: ref(null),
			gridName: ref("请选择所属网格"),
			enterpriseGridShow: ref(false),

			enterpriseUserEn: ref("请选择监管人员"),
			enterpriseUserShow: ref(false),
			enterpriseUserList: ref([]),
			enterpriseUserResult: ref([]),
			enterpriseUserResultName: ref([]),
			enterpriseLevelEn: ref("请选择监管等级"),
			enterpriseLevelList: ref(null),
			enterpriseLevelShow: ref(false),
			enterpriseSurfaceSrcList: ref([]),
			enterpriseStoreSrcList: ref([]),
			mtImgList: ref([]),
			snImgList: ref([]),
			enterpriseStateEn: ref("请选择企业类型"),
			enterpriseStateList: ref(null),
			enterpriseStateShow: ref(false),
			enterpriseLicenceStimeEn: ref("开始日期"),
			enterpriseLicenceEtimeEn: ref("结束日期"),
			cateringScaleEn: ref("暂无"),
			enterpriseAddressShow: ref(false),
			//地图
			keyWords: ref(null),
			coordinate: ref(null),
			enterpriseAddressTemp: ref(null),
			enterpriseLongitudeTemp: ref(null),
			enterpriseLatitudeTemp: ref(null),
			enterpriseAddressText: ref("请选择经纬度"),

			enterpriseIsSchoolList: ref(null),
			enterpriseIsSchoolEn: ref("请选择是否在学校周边"),
			enterpriseIsSchoolShow: ref(false),
			enterpriseIsSchool: ref(null),

			relationId: ref(null),
			enterpriseId: ref(null),
			enterpriseModel: ref("0"),
			enterpriseQrNumber: proxy.$router.currentRoute.value.params.id,
			enterpriseName: ref(null),
			enterpriseType: ref(null),
			enterpriseLicenceStime: ref(null),
			enterpriseLicenceEtime: ref(null),
			enterpriseNumber: ref(null),
			enterpriseLicence: ref(null),
			enterpriseLicenceSrc: ref(null),
			enterpriseLicense: ref(null),
			salesProject: ref(null),
			enterpriseContact: ref(null),
			enterpriseContactTel: ref(null),
			enterpriseHead: ref(null),
			enterpriseSecurity: ref(null),
			enterpriseNote: ref(null),
			enterpriseGame: ref(null),
			gridId: ref(null),
			enterpriseUser: ref(null),
			enterpriseLevel: ref(null),
			enterpriseSurfaceSrc: ref(null),
			enterpriseStoreSrc: ref(null),
			enterpriseState: proxy.$router.currentRoute.value.params.id.indexOf("G") == -1 ? "0" : "1",
			enterpriseAddress: ref(null),
			enterpriseLongitude: ref(null),
			enterpriseLatitude: ref(null),
			cateringScale: ref(null),
			enterpriseArea: ref(null),
			enterpriseWarehouseAddress: ref(null),

			defaultIndex: ref(null),
			showAddList: ref(false),
			addressArr: ref([])
		});

		if (dataE.enterpriseData != null) {
			Object.assign(dataAll, dataE.enterpriseData);
			dataAll.gridName = !dataAll.gridName?'请选择所属网格':dataAll.gridName
			if (dataAll.enterpriseLevel) {
				dataAll.enterpriseLevelEn = dataAll.enterpriseLevel;
			}
			if (dataAll.enterpriseLatitude && dataAll.enterpriseLongitude) {
				dataAll.enterpriseAddressText = dataAll.enterpriseLatitude + "," + dataAll.enterpriseLongitude;
			}
			if (dataAll.enterpriseLicenceStime && dataAll.enterpriseLicenceEtime) {
				dataAll.currentDate = new Date(dataAll.enterpriseLicenceStime);
				dataAll.currentDateEn = new Date(dataAll.enterpriseLicenceEtime);
				dataAll.enterpriseLicenceStimeEn = dataAll.enterpriseLicenceStime;
				dataAll.enterpriseLicenceEtimeEn = dataAll.enterpriseLicenceEtime;
			}
			dataAll.enterpriseAddressTemp = dataAll.enterpriseAddress;
			dataAll.enterpriseLongitudeTemp = dataAll.enterpriseLongitude;
			dataAll.enterpriseLatitudeTemp = dataAll.enterpriseLatitude;

			if (dataAll.userTsMapList != null && dataAll.userTsMapList[0] != undefined) {
				let nameArr = dataAll.userTsMapList.map(u=>{ return u.userNikeName})
				dataAll.enterpriseUserEn = nameArr? (nameArr.length>1? nameArr.join('、') : nameArr[0]) : '请选择监管人员'
				dataAll.enterpriseUserResult = dataAll.userTsMapList.map(u=>{return u.userId});
				dataAll.enterpriseUserResultName = nameArr
			}

			// 初始化链接
			dataAll.xkImgList = ref([])
			if (dataAll.enterpriseLicenceSrc != null) {
				dataAll.xkImgList.push({ url: baseUrlImg() + dataAll.enterpriseLicenceSrc });
			}
			dataAll.yyImgList = ref([])
			if (dataAll.enterpriseLicense != null) {
				dataAll.yyImgList.push({ url: baseUrlImg() + dataAll.enterpriseLicense });
			}

			dataAll.enterpriseStoreSrc;
			dataAll.enterpriseSurfaceSrc;

			dataAll.snImgList = ref([])
			if (dataAll.enterpriseStoreSrcList) {
				for (var i = 0; i < dataAll.enterpriseStoreSrcList.length; i++) {
					dataAll.snImgList.push({ url: baseUrlImg() + dataAll.enterpriseStoreSrcList[i] });
				}
			} else {
				dataAll.enterpriseStoreSrcList = ref([]);
			}

			dataAll.mtImgList = ref([])
			if (dataAll.enterpriseSurfaceSrcList) {
				for (var i = 0; i < dataAll.enterpriseSurfaceSrcList.length; i++) {
					dataAll.mtImgList.push({ url: baseUrlImg() + dataAll.enterpriseSurfaceSrcList[i] });
				}
			} else {
				dataAll.enterpriseSurfaceSrcList = ref([]);
			}
		}

		const fan = () => {
			router.push({
				path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
			});
		}

		const addRestaurant = () => {
			store.commit('enterprise/setEditData', dataAll)
			router.push({ path: '/enterpriseAdd/1'});
		}

		// 许可证期限起
		const showDateButton = () => {
			dataAll.showDate = true;
		}
		const dateCancel = () => {
			dataAll.showDate = false;
		}
		const dateConfirm = (value) => {
			dataAll.enterpriseLicenceStimeEn = dateToStrCopy(new Date(value));
			dataAll.enterpriseLicenceStime = dateToStrCopy(new Date(value))
			dataAll.showDate = false;
		}

		// 许可证期限止
		const showDateButtonEnd = () => {
			dataAll.showDateEnd = true;
		}
		const dateCancelEnd = () => {
			dataAll.showDateEnd = false;
		}
		const dateConfirmEnd = (value) => {
			dataAll.enterpriseLicenceEtime = dateToStrCopy(new Date(value))
			dataAll.enterpriseLicenceEtimeEn = dateToStrCopy(new Date(value));
			dataAll.enterpriseLicenceEtime = dateToStrCopy(new Date(value))
			dataAll.showDateEnd = false;
		}

		// 主体状态
		const selectPicker = () => {
			proxy.$http({
				url: proxy.$http.adornUrl(`/sys/type/getEnterpriseType`),
				method: 'post',
				data: null,
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then(({
				data
			}) => {
				if (data && data.code === 0) {
					const array = [];
					for (var i = 0; i < data.type.length; i++) {
						array.push({
							text: data.type[i].value,
							id: data.type[i].key
						});
					}
					dataAll.enterpriseTypeList = array;
					dataAll.defaultIndex = dataAll.enterpriseTypeList.findIndex((ace) => ace.id == dataAll
						.enterpriseType);
					dataAll.selectPickerShow = true;
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			});
		}
		const selectPickerCancel = () => {
			dataAll.selectPickerShow = false;
		}
		const selectPickerConfirm = (value) => {
			dataAll.enterpriseTypeEn = value.text;
			dataAll.enterpriseType = value.id;
			dataAll.selectPickerShow = false;
		}

		// 所属辖区
		const selectEnterpriseGame = () => {
			proxy.$http({
				url: proxy.$http.adornUrl(`/sys/type/getGame`),
				method: 'post',
				data: null,
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then(({
				data
			}) => {
				if (data && data.code === 0) {
					const array = [];
					for (var i = 0; i < data.type.length; i++) {
						array.push({
							text: data.type[i].gameName,
							id: data.type[i].gameId
						});
					}
					dataAll.enterpriseGameList = array;
					dataAll.defaultIndex = dataAll.enterpriseGameList.findIndex((ace) => ace.id == dataAll
						.enterpriseGame);
					dataAll.enterpriseGameShow = true;
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		const enterpriseGameCancel = () => {
			dataAll.enterpriseGameShow = false;
		}
		const enterpriseGameConfirm = (value) => {
			if (dataAll.enterpriseGame != value.id) {
				dataAll.enterpriseUser = null;
				dataAll.enterpriseUserResult = [];
				dataAll.enterpriseUserResultName = [];
				dataAll.enterpriseUserEn = "请选择监管人员";
				// 网格信息置空
				dataAll.gridId = null;
				dataAll.enterpriseGridList = [];
				dataAll.gridEn = '请选择所属网格';
			}
			dataAll.enterpriseGameEn = value.text;
			dataAll.enterpriseGame = value.id;
			dataAll.enterpriseGameShow = false;
		}

		// 网格
		const selectGids = () => {
			proxy.$http({
				url: proxy.$http.adornUrl(`/sys/type/getGridsByGame`),
				method: 'post',
				data: { gameId: dataAll.enterpriseGame },
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then( ({data}) => {
				if(data.code==0) {
					dataAll.enterpriseGridList = data.type.map(g => { return {id: g.gridId, text: g.gridName }})
					dataAll.enterpriseGridShow = true

				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.type
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		const enterpriseGridCancel = () => {
			dataAll.enterpriseGridShow = false
		}
		const enterpriseGridConfirm = (val) => {
			dataAll.gridName = val.text
			dataAll.gridId = val.id
			dataAll.enterpriseGridShow = false
		}
		
		// 监管人员
		const selectEnterpriseUser = () => {
			if (!dataAll.enterpriseGame) {
				proxy.$toast.fail("请先选择所属辖区");
				return;
			}
			proxy.$http({
				url: proxy.$http.adornUrl(`/sys/user/listAll`),
				method: 'post',
				data: {
					'userGame': dataAll.enterpriseGame
				},
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then(({
				data
			}) => {
				if (data && data.code === 0) {
					dataAll.enterpriseUserList = data.list;
					dataAll.enterpriseUserShow = true;
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		const enterpriseUserCancel = () => {
			dataAll.enterpriseUserShow = false;
			dataAll.enterpriseUserResult = dataAll.userTsMapList.map(u=>{return u.userId});
		}
		const enterpriseUserConfirm = (value) => {
			dataAll.enterpriseUserResultName = []
			dataAll.enterpriseUserList.forEach(item => {
				dataAll.enterpriseUserResult.forEach(_item => {
					if (item.userId == _item) {
						dataAll.enterpriseUserResultName.push(item.userName);
					}
				})
			})
			dataAll.enterpriseUserEn = dataAll.enterpriseUserResultName? 
			(dataAll.enterpriseUserResultName.length>1? dataAll.enterpriseUserResultName.join('、') : dataAll.enterpriseUserResultName[0])
			:'请选择监管人员';
			dataAll.enterpriseUserShow = false;
		}
		const toggle = (item) => {
			const index = dataAll.enterpriseUserResult.indexOf(item.userId);
			if (index == -1) {
				dataAll.enterpriseUserResult.push(item.userId);
			} else {
				dataAll.enterpriseUserResult.splice(index, 1);
			}
		}

		const changeGroup = (val) => {
			dataAll.enterpriseUserResult = val;
		}

		// 监管等级
		const selectEnterpriseLevel = () => {
			dataAll.enterpriseLevelList = [{
				text: "A"
			}, {
				text: "B"
			}, {
				text: "C"
			}];
			dataAll.defaultIndex = dataAll.enterpriseLevelList.findIndex((ace) => ace.text == dataAll
				.enterpriseLevel);
			dataAll.enterpriseLevelShow = true;
		}
		const enterpriseLevelCancel = () => {
			dataAll.enterpriseLevelShow = false;
		}
		const enterpriseLevelConfirm = (value) => {
			dataAll.enterpriseLevelEn = value.text;
			dataAll.enterpriseLevel = value.text;
			dataAll.enterpriseLevelShow = false;
		}

		const addXkImg = (file) => {
			proxy.$toast.loading({ message: '上传中...', forbidClick: true })
			new Compressor(file, {
				quality: 0.4,
				success(res) {
					const formData = new FormData();
					formData.append('file', res, res.name);
					proxy.$http({
						url: proxy.$http.adornUrl(`/app/enterprise/addFile`),
						method: 'post',
						data: formData,
						headers: {
							token: sessionStorage.getItem("userToken")
						},
						cache: false,
					}).then(({ data }) => {
						proxy.$toast.clear()
						if (data && data.code === 0) {
							dataAll.enterpriseLicenceSrc = data.data;
							dataAll.xkImgList = [{url: baseUrlImg() +dataAll.enterpriseLicenceSrc }]
						} else if (data.code === 401) {
							sessionStorage.setItem("userToken", "");
							router.push({
								path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
							});
						} else {
							proxy.$toast.fail(data.msg);
						}
					})
				},
				error(res) {
					console.log(res)
				}
			})
		}

		const xkImg = () => {
			dataAll.enterpriseLicenceSrc = ref(null);
			dataAll.xkImgList = ref([])
		}

		const addYyImg = (file) => {
			proxy.$toast.loading({ message: '上传中...', forbidClick: true })
			new Compressor(file, {
				quality: 0.4,
				success(res) {
					const formData = new FormData();
					formData.append('file', res, res.name);
					proxy.$http({
						url: proxy.$http.adornUrl(`/app/enterprise/addFile`),
						method: 'post',
						data: formData,
						headers: {
							token: sessionStorage.getItem("userToken")
						},
						cache: false,
					}).then(({ data }) => {
						proxy.$toast.clear()
						if (data && data.code === 0) {
							dataAll.enterpriseLicense = data.data;
							dataAll.yyImgList = [{url: baseUrlImg() + dataAll.enterpriseLicense}]
						} else if (data.code === 401) {
							sessionStorage.setItem("userToken", "");
							router.push({
								path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
							});
						} else {
							proxy.$toast.fail(data.msg);
						}
					})
				},
				error(res) {
					console.log(res)
				}
			})
		}

		const yyImg = () => {
			dataAll.enterpriseLicense = ref(null);
			dataAll.yyImgList = ref([])
		}

		const addMtImg = (file) => {
			proxy.$toast.loading({ message: '上传中...', forbidClick: true })
			new Compressor(file, {
				quality: 0.4,
				success(res) {
					const formData = new FormData();
					formData.append('file', res, res.name);
					proxy.$http({
						url: proxy.$http.adornUrl(`/app/enterprise/addFile`),
						method: 'post',
						data: formData,
						headers: {
							token: sessionStorage.getItem("userToken")
						},
						cache: false,
					}).then(({ data }) => {
						proxy.$toast.clear()
						if (data && data.code === 0) {
							dataAll.enterpriseSurfaceSrcList.push(data.data);
							dataAll.enterpriseSurfaceSrc = dataAll.enterpriseSurfaceSrcList.join(',');
							dataAll.mtImgList = dataAll.enterpriseSurfaceSrcList.map(mt=>{
								return { url: baseUrlImg() + mt }
							})
						} else if (data.code === 401) {
							sessionStorage.setItem("userToken", "");
							router.push({
								path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
							});
						} else {
							proxy.$toast.fail(data.msg);
						}
					})
				},
				error(res) {
					console.log(res)
				}
			})
		}

		const mtImg = (file, detail) => {
			dataAll.enterpriseSurfaceSrcList.splice(detail.index, 1);
			dataAll.enterpriseSurfaceSrc = dataAll.enterpriseSurfaceSrcList.join(',');
			dataAll.mtImgList = dataAll.enterpriseSurfaceSrcList.map(mt=>{
				return { url: baseUrlImg() + mt }
			})
		}

		const addSnImg = (file) => {
			proxy.$toast.loading({ message: '上传中...', forbidClick: true })
			new Compressor(file, {
				quality: 0.4,
				success(res) {
					const formData = new FormData();
					formData.append('file', res, res.name);
					proxy.$http({
						url: proxy.$http.adornUrl(`/app/enterprise/addFile`),
						method: 'post',
						data: formData,
						headers: {
							token: sessionStorage.getItem("userToken")
						},
						cache: false,
					}).then(({ data }) => {
						proxy.$toast.clear()
						if (data && data.code === 0) {
							dataAll.enterpriseStoreSrcList.push(data.data);
							dataAll.enterpriseStoreSrc = dataAll.enterpriseStoreSrcList.join(',');
							dataAll.snImgList = dataAll.enterpriseStoreSrcList.map(sn=>{
								return { url: baseUrlImg() + sn }
							})
						} else if (data.code === 401) {
							sessionStorage.setItem("userToken", "");
							router.push({
								path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
							});
						} else {
							proxy.$toast.fail(data.msg);
						}
					})
				},
				error(res) {
					console.log(res)
				}
			})
		}

		const snImg = (file, detail) => {
			dataAll.enterpriseStoreSrcList.splice(detail.index, 1);
			dataAll.enterpriseStoreSrc = dataAll.enterpriseStoreSrcList.join(',');
			dataAll.snImgList = dataAll.enterpriseStoreSrcList.map(sn=>{
				return { url: baseUrlImg() + sn }
			})
		}
		
		let geolocation = new qq.maps.Geolocation("6R5BZ-HBYWQ-XW25T-GFO7X-UMMLS-FEFXU", "qr");
		let options = { timeout: 9000 };
		let map = {};
		let marker = {};

		let mapOptions = {
			zoom: 15, //设置地图缩放级别
			// center: myLatlng, //设置中心点样式
			mapTypeId: qq.maps.MapTypeId.ROADMAP //设置地图样式详情参见MapType
		}

		let showErr = () => {}

		let showPosition = (position) => {
			if (!dataAll.enterpriseLatitude && !dataAll.enterpriseLongitude) {
				dataAll.enterpriseAddressTemp = position.nation + position.city + position.addr;
				dataAll.enterpriseLongitudeTemp = position.lng;
				dataAll.enterpriseLatitudeTemp = position.lat;
			}
			//步骤：定义map变量 调用 qq.maps.Map() 构造函数   获取地图显示容器
			//设置地图中心点
			var myLatlng = new qq.maps.LatLng(dataAll.enterpriseLatitudeTemp, dataAll.enterpriseLongitudeTemp);
			mapOptions.center = myLatlng
			//获取dom元素添加地图信息
			map = new qq.maps.Map(document.getElementById("container"), mapOptions);

			//创建一个Marker
			marker = new qq.maps.Marker({
				//设置Marker的位置坐标
				position: myLatlng,
				//设置显示Marker的地图
				map: map
			});

			//给地图添加点击事件
			//并获取鼠标点击的经纬度
			qq.maps.event.addListener(map, 'click', function(event) {
				console.log(event);
				//创建一个Marker
				marker.setMap(null);
				marker = new qq.maps.Marker({
					//设置Marker的位置坐标
					position: new qq.maps.LatLng(event.latLng.getLat(), event.latLng.getLng()),
					//设置显示Marker的地图
					map: map
				});
				dataAll.enterpriseLongitudeTemp = event.latLng.getLng();
				dataAll.enterpriseLatitudeTemp = event.latLng.getLat();
				proxy.$http({
					url: proxy.$http.adornUrl(`/app/qr/getAddress`),
					method: 'post',
					data: {
						enterpriseLongitude: event.latLng.getLng(),
						enterpriseLatitude: event.latLng.getLat()
					},
					headers: {
						token: sessionStorage.getItem("userToken")
					},
				}).then(({
					data
				}) => {
					if (data.code === 401) {
						sessionStorage.setItem("userToken", "");
						router.push({
							path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
						});
					}
					dataAll.enterpriseAddressTemp = data.data.result.address + data.data.result.formatted_addresses.recommend;
				})
			});
		}
		// 地图描点确定经纬度
		const selectEnterpriseAddress = () => {
			dataAll.enterpriseAddressShow = true;
			window.setTimeout(function() {
				geolocation.getLocation(showPosition, showErr, options);
			}, 380);
		}
		const enterpriseAddressCancel = () => {
			dataAll.enterpriseAddressShow = false;
			dataAll.enterpriseAddressTemp = dataAll.enterpriseAddress;
		}
		const enterpriseAddressConfirm = () => {
			dataAll.enterpriseAddress = dataAll.enterpriseAddressTemp;
			dataAll.enterpriseLongitude = dataAll.enterpriseLongitudeTemp;
			dataAll.enterpriseLatitude = dataAll.enterpriseLatitudeTemp;
			dataAll.enterpriseAddressText = dataAll.enterpriseLatitudeTemp + "," + dataAll.enterpriseLongitudeTemp;
			dataAll.enterpriseAddressShow = false;
		}

		const queryAddressByKeyWord = () => {
			let keyword = dataAll.enterpriseAddressTemp
			proxy.$http.request({
				url: proxy.$http.adornUrl(`/app/qr/getDegreeList?address=${keyword}`),
			    method: 'get',
			    headers:{
			      	token:sessionStorage.getItem("userToken")
			    }
			}).then(({data}) => {
				if(data&&data.code==0) {
					let addArr = data.data.map(a=>{ return { id: a.id, address: a.address, location: a.location }})
					dataAll.addressArr = addArr
					dataAll.showAddList = true
				}else if(data.code===401){
					sessionStorage.setItem("userToken","");
			        router.push({path:'/enterpriseJump/'+proxy.$router.currentRoute.value.params.id});
				}else{
					proxy.$toast.fail(data.msg);
				}
			})
		}

		const selectAddItem = (item) => {
			dataAll.showAddList = false
			if(item.address) {
				dataAll.enterpriseLatitudeTemp = item.location.lat
				dataAll.enterpriseLongitudeTemp = item.location.lng
				dataAll.enterpriseAddressTemp = item.address
				let centerPoint = new qq.maps.LatLng(dataAll.enterpriseLatitudeTemp, dataAll.enterpriseLongitudeTemp);
				if(map) {
					map.setCenter(centerPoint)
					marker.setPosition(centerPoint)
				} 
			}
		}

		// 是否学校周边
		const selectEnterpriseIsSchool = () => {
			dataAll.enterpriseIsSchoolList = [
				{ text: "是", id: 0 },
				{ text: "否", id: 1 }
			];
			dataAll.defaultIndex = dataAll.enterpriseIsSchoolList.findIndex((ace) => ace.id == dataAll.enterpriseIsSchool);
			dataAll.enterpriseIsSchoolShow = true;
		}
		const enterpriseIsSchoolCancel = () => {
			dataAll.enterpriseIsSchoolShow = false;
		}
		const enterpriseIsSchoolConfirm = (value) => {
			dataAll.enterpriseIsSchoolEn = value.text;
			dataAll.enterpriseIsSchool = value.id;
			dataAll.enterpriseIsSchoolShow = false;
		}
	
		// 更新企业信息
		const addE = () => {
			// let json1 = JSON.stringify(dataAll.enterpriseUserResult).trim();
			const json = {
				relationId: dataAll.relationId,
				enterpriseId: dataAll.enterpriseId,
				enterpriseModel: dataAll.enterpriseModel,
				enterpriseName: dataAll.enterpriseName,
				enterpriseType: dataAll.enterpriseType,
				enterpriseLicenceStime: dataAll.enterpriseLicenceStime,
				enterpriseLicenceEtime: dataAll.enterpriseLicenceEtime,
				enterpriseNumber: dataAll.enterpriseNumber,
				enterpriseLicence: dataAll.enterpriseLicence,
				enterpriseLicenceSrc: dataAll.enterpriseLicenceSrc,
				enterpriseLicense: dataAll.enterpriseLicense,
				salesProject: dataAll.salesProject,
				enterpriseContact: dataAll.enterpriseContact,
				enterpriseContactTel: dataAll.enterpriseContactTel,
				enterpriseHead: dataAll.enterpriseHead,
				enterpriseSecurity: dataAll.enterpriseSecurity,
				enterpriseNote: dataAll.enterpriseNote,
				enterpriseGame: dataAll.enterpriseGame,
				gridId: dataAll.gridId,
				enterpriseUser: dataAll.enterpriseUserResult.join(','),
				enterpriseLevel: dataAll.enterpriseLevel,
				enterpriseSurfaceSrc: dataAll.enterpriseSurfaceSrc,
				enterpriseStoreSrc: dataAll.enterpriseStoreSrc,
				enterpriseState: dataAll.enterpriseState,
				enterpriseModel: dataAll.enterpriseModel,
				enterpriseQrNumber: dataAll.enterpriseQrNumber,
				cateringScale: dataAll.cateringScale,
				enterpriseArea: dataAll.enterpriseArea,
				enterpriseAddress: dataAll.enterpriseAddress,
				enterpriseLongitude: dataAll.enterpriseLongitude,
				enterpriseIsSchool: dataAll.enterpriseIsSchool,
				enterpriseWarehouseAddress: dataAll.enterpriseWarehouseAddress,
				enterpriseLatitude: dataAll.enterpriseLatitude
			}

			proxy.$http({
				url: proxy.$http.adornUrl(`/app/qr/addUpdateEnterprise`),
				method: 'post',
				data: json,
				headers: {
					token: sessionStorage.getItem("userToken")
				},
			}).then(({
				data
			}) => {
				if (data && data.code === 0) {
					router.push({
						path: '/enterpriseJump/' + data.data
					});
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}

		return {
			...toRefs(dataAll),
			fan,
			showDateButton,
			showDateButtonEnd,
			dateCancel,
			dateConfirm,
			dateCancelEnd,
			dateConfirmEnd,
			selectPicker,
			selectPickerCancel,
			selectPickerConfirm,
			addXkImg,
			xkImg,
			addYyImg,
			yyImg,
			selectEnterpriseGame,
			enterpriseGameCancel,
			enterpriseGameConfirm,
			selectEnterpriseUser,
			enterpriseUserCancel,
			enterpriseUserConfirm,
			toggle,
			addRestaurant,
			changeGroup,
			selectEnterpriseLevel,
			enterpriseLevelCancel,
			enterpriseLevelConfirm,
			selectEnterpriseIsSchool,
			enterpriseIsSchoolCancel,
			enterpriseIsSchoolConfirm,
			addMtImg,
			mtImg,
			addSnImg,
			snImg,
			addE,
			selectEnterpriseAddress,
			enterpriseAddressCancel,
			enterpriseAddressConfirm,

			queryAddressByKeyWord,
			selectAddItem,
			selectGids,enterpriseGridCancel,enterpriseGridConfirm
		}
	}
}
