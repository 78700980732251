import { getCurrentInstance, reactive, toRefs, provide } from "vue"
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
	setup(props, context) {
		//开始创建组件之前
		console.log("开始创建组件之前");
		const { proxy } = getCurrentInstance();
		const router = useRouter();
		const store = useStore();
		// const userToken = sessionStorage.getItem("userToken");

		let dataAll = reactive({
			enterpriseData: {},
			enterpriseModel: null
		});
		
		let backFrom = proxy.$router.currentRoute.value.query.from
		console.log('backFrom: ======== ' + backFrom)
		
		if(!backFrom){ // 首次进入请求
			proxy.$http({
				url: proxy.$http.adornUrl(`/app/qr/getEnterprise`),
				method: 'post',
				data: proxy.$http.adornData({
					'enterpriseQrNumber': proxy.$router.currentRoute.value.params.id,
				})
			}).then(({
				data
			}) => {
				if (data && data.code === 0) {
					dataAll.enterpriseData = data.data;
					dataAll.enterpriseModel = data.data.enterpriseModel;
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id,
					});
				} else {
					proxy.$toast(data.msg);
				}
			});
		} else { // 新增或编辑从业人员，特设页面返回 进入企业上报信息状态管理取值
			dataAll.enterpriseData = store.state.enterprise.editData
			dataAll.enterpriseModel = store.state.enterprise.editData.enterpriseModel
		}

		provide('dataAll', dataAll);

		return {
			...toRefs(dataAll)
		}
	}
}
